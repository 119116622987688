<template>
  <div>
    <vx-card actionable class="cardx" title="Payment Processed Records">
      <vs-row type="flex" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-input style="margin-top: -2%;" type="text" label-placeholder="MR Number" v-model="mr_number" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            label-placeholder="Process Data"
            :options="processedData"
            v-model="processed_data"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-input style="margin-top: -2%;" type="text" label-placeholder="Transaction Number" v-model="transaction_number" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            label-placeholder="cities"
            :options="documentType"
            v-model="doc_type"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="2"
        >
          <vs-button
          radius
          class="gap"
            color="dark"
            type="gradient"
            icon="search"
            @click="getProcessedrecorddata()"
          ></vs-button>
          <vs-button
          style="margin-left:3%"
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vx-card actionable class="cardx" style="margin-top: 3%">
        <vs-table :data="SpocName">
          <template slot="thead">
            <vs-th>Person Name</vs-th>
            <vs-th>Mobile</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Transaction No.</vs-th>
            <vs-th>Document Type</vs-th>
            <vs-th>MR Number</vs-th>
            <vs-th>MR Date</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td >
                {{ data[indextr].person_name }}  
                </vs-td>
              <vs-td>
                {{ data[indextr].mobile_number }}
              </vs-td>
              <vs-td>
                {{ data[indextr].email }}
              </vs-td>
              <vs-td>
                {{ data[indextr].transaction_no }}
              </vs-td>
              <vs-td>
                {{ data[indextr].doc_type }}
              </vs-td>
              <vs-td>
                {{ data[indextr].mr_number }}
              </vs-td>
              <vs-td>
                {{ data[indextr].mr_date }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
      <vs-row style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="12"
        >
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination
            :total="tablelinks"
            v-model="currentpage" 
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
// import eventbus from "../components/eventbus";
export default {
  mounted() {
    this.getProcessedrecorddata();
    // this.spocscopy = [];
    // this.cities = [];
    // this.spocs = [];
    // this.getSpocs(this.selectedTeam);
    // if (this.team == "CM") {
    //   this.getSpocs(this.selectedTeam);
    // } else if (this.team == "Exed") {
    //   this.getExed();
    // }
  },
  data() {
    return {
      mr_number: "",
      processedData: [
        "Processed",
        "Un-Processed"
      ],
      processed_data: "",
      transaction_number: "",
      documentType: [
        "upi",
        "terminal",
        "payu"
      ],
      doc_type: "",
      countsleads: 0,
      currentpage: 1,
      tablelinks: 1,
      from_spoc_name: "",
      iiml_spocs: [],
      team_select: this.team,
      colorx: "rgb(199, 42, 117)",
      editspoctitle: "",
      mhpeditspoctitle: "",
      netEnquiriesPopup: false,
      mhpnetEnquiriesPopup: false,
      mhpeditNetEnquiriecount: "",
      editNetEnquiriecount: "",
      selectedColoumnSpoc: "",
      mhpselectedColoumnSpoc: "",
      mhp_iiml_spocs: [],
      cities: [],
      sortSpoc: [],
      spocs: [],
      selected_spoc: "",
      selected_city: "",
      cityString: "",
      SpocId: "",
      currentTablePage: "",
      SpocName: [],
      processid: ""
    };
  },
  components: {
    "v-select": vSelect,
  },
  watch: {
    currentpage(val) {
      console.log("pagination",val);
      this.getProcessedrecorddata(val);
    },
    processed_data(value) {
      if(value === "Processed") {
        this.processid = 1;
      } else if(value === "Un-Processed") {
        this.processid = 0;
      }

    }
  },
  methods: {
    getProcessedrecorddata(page_num) {
      let obj = {
        page: page_num,
        mr_number: this.mr_number,
        processed_data: this.processid,
        transaction_no: this.transaction_number,
        doc_type: this.doc_type
      }
      if(obj.mr_number === "" && obj.processed_data === "" && obj.transaction_no === "" && obj.doc_type === "") {
        let url = `${constants.SERVER_API}queryProcessedExcelFileRecords`;
        axios
          .get(url,  {
            params: {page : page_num},
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("Exed", response);
            this.countsleads = response.data.receipt_data.total;
            this.currentTablePage = response.data.receipt_data.current_page;
            this.tablelinks = response.data.receipt_data.last_page;
            this.SpocName = response.data.receipt_data.data;
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        let url = `${constants.SERVER_API}queryProcessedExcelFileRecords`;
        axios
          .get(url, {
            params: obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("Exed", response);
            this.countsleads = response.data.receipt_data.total;
            this.currentTablePage = response.data.receipt_data.current_page;
            this.tablelinks = response.data.receipt_data.last_page;
            this.SpocName = response.data.receipt_data.data;
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
      
    },
    refreshdata() {
       this.mr_number = "";
       this.processid = "";
       this.transaction_number = "";
       this.doc_type = "";
      let url = `${constants.SERVER_API}queryProcessedExcelFileRecords`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("Exed", response);
            this.countsleads = response.data.receipt_data.total;
            this.currentTablePage = response.data.receipt_data.current_page;
            this.tablelinks = response.data.receipt_data.last_page;
            this.SpocName = response.data.receipt_data.data;
          })
          .catch((error) => {
            this.handleError(error);
          });
    }
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
.con-vs-popup .vs-popup {
  width: 400px;
}
</style>
